import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import PrimaryButton from "../components/primary-button"
import PaperlessAwardsVideo from "../components/paperless-awards-video"
import { Link } from "gatsby"

const PaperlessPage = () => (
  <Background>
    <Layout>
      <Seo title="Nomination Guidance" />
      <Container>
        <PageTitle text="Nomination Guidance" />
        <div style={{
          textAlign: `center`,
          backgroundColor: `rgba(0,0,0,0.6)`,
          paddingBottom: `4rem`
        }}>
          <div style={{
            maxWidth: `34rem`,
            margin: `auto`,
            padding: `5rem 2rem 1rem 2rem`
          }}>
            <span style={{
              display: `inline`,
              backgroundColor: `#d23464`,
              padding: `.5rem 0`,
              lineHeight: `3.4rem`,
              boxShadow: `.5rem 0 0 #d23464, -.5rem 0 0 #d23464`,
              textTransform: `uppercase`,
              color: `#fff`,
              fontFamily: `Din Pro`,
              fontWeight: `700`,
              fontSize: `1.6rem`
            }}>Crafting an award-winning entry</span>
          </div>
        </div>
        <div style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(210,52,100,.9) 0%, rgba(251,146,1,.9) 100%)`
        }}>
          <Container>
            <div class="row" style={{ padding: `2rem`}}>
              <div class="col-sm">
                <p>We are thrilled to invite you to submit your nominations for this year's prestigious award. As you embark on this exciting journey, we want to provide you with guidance on what our esteemed judges will be seeking in the nominations.</p>
                <p>Firstly, a compelling nomination should unveil a well-defined strategy for the project, showcasing clear communication with both staff and stakeholders. Articulate how the strategy was not only crafted but effectively conveyed to all involved.</p>
                <p>Additionally, the judges are eager to see manifestations of innovation, imagination, and ambition within your project. We encourage you to narrate the unique aspects that set your initiative apart, demonstrating a keen spirit for pushing boundaries.</p>
                <p>A critical element the judges will be looking for is evidence of strong project management. Share insights into how the project was meticulously planned, executed, and controlled, ensuring its seamless progression.</p>
                <p>Moreover, the financial aspect is paramount. Present a solid case for value for money, elucidating how resources were optimally utilised to yield maximum results.</p>
              </div>
              <div class="col-sm">
                <p>Highlight the progress your project has made or is well on its way to achieving its objectives. Convey the tangible impact and benefits that are being realised.</p>
                <p>In terms of impact on services and stakeholders, provide supporting evidence that showcases the positive difference your project or policy has made. Whether it's enhancing user experience or elevating customer satisfaction, illustrate the tangible outcomes.</p>
                <p>Lastly, align your submission with the specified considerations outlined in the category description. Offer a clear and compelling demonstration of how these considerations were not just met but exceeded.</p>
                <p>Your nomination is a testament to your dedication and hard work, and we look forward to reading about the remarkable accomplishments within your project. Best of luck in your submission!</p>
                <PrimaryButton to="/enter/" text="How to Enter Smarter Working Live" />
                <Link to="/categories/" className="btn" style={{
                  textTransform: `uppercase`,
                  color: `#fff`,
                  fontWeight: `bold`,
                  border: `2px solid #fff`,
                  padding: `0.6rem 1rem`,
                  borderRadius: `0`,
                  marginTop: `1rem`
                }}>View the Categories</Link>
              </div>
            </div>
          </Container>
        </div>
        {/* <PaperlessAwardsVideo title="Highlights from 2019" /> */}
        <div style={{marginTop: `100px`}}></div>
      </Container>
    </Layout>
  </Background>
)

export default PaperlessPage
